import React from 'react'
import PropTypes from 'prop-types'
import styles from './basic-page.module.scss'
import RichText from '../../components/rich-text'
import Container from '../../components/container'

const BasicPageTemplate = ({ title, content }) => {
  return (
    <div className={styles.el}>
      <Container>
        <div className={styles.el__content}>
          <h1 className={styles.el__title}>{title}</h1>
          <div className={styles.el__content}>
            <RichText render={content.raw} />
          </div>
        </div>
      </Container>
    </div>
  )
}

BasicPageTemplate.defaultProps = {
  title: '',
  content: '',
}

BasicPageTemplate.propTypes = {
  title: PropTypes.string,
  content: PropTypes.shape({
    raw: PropTypes.array.isRequired.isRequired,
    html: PropTypes.string,
  }),
}

export default BasicPageTemplate
